<template>
  <div id="TextEditor">
    <div id="TextEditorNav">
      <div ref='toolbarContainer'></div>
    </div>
    <div id="TextEditorMain">
      <div id="TextEditorMainCode">
        <div ref='textContainer'></div>
      </div>
<!--      <div id="TextEditorMainShow" v-html="articleHtml"></div>-->
    </div>
  </div>
</template>

<script>
  import WangEditor from 'wangeditor';
  // 设置菜单
  const menus = [
    'head',
    'bold',
    'fontSize',
    'fontName',
    'italic',
    'underline',
    'strikeThrough',
    'indent',
    'lineHeight',
    'foreColor',
    'backColor',
    'link',
    'todo',
    'justify',
    'quote',
    'emoticon',
    'image',
    'splitLine',
  ]
  const LOCAL_BASE_URL = '/api';
  export default {
    name: "TextEditor",
    data() {
      return {
        articleHtml: "",
        editor: null
      }
    },
    props:{
      value:{
        type: String,
        default: ''
      },
      //获取编辑栏是否禁用
      disable:{
        type: Boolean,
      }
  },
    watch:{
      value(val) {
        if(val !== this.editor.txt.html())
          this.editor.txt.html(val)
        // this.editor.selection.moveCursor(this.editor.$textElem.elems[0],false);
      }
    },
  mounted() {
      // 设置工具栏和编辑区分开
      this.editor = new WangEditor(this.$refs.toolbarContainer, this.$refs.textContainer);
      const editor = this.editor;
      // 设置编辑器层级
      editor.config.zIndex = 1;
      // 设置内容变化事件
      editor.config.onchange = (newHtml) => {
        this.onChange(newHtml)
      };
      // 设置placeholder
      editor.config.placeholder = '请输入公告内容';
      // 图片菜单配置
      editor.config.menus = menus;
      // 图片上传配置
      //隐藏插入网络图片的功能
      editor.config.showLinkImg = false;
      // 去除网络图片说明
      editor.config.showLinkImgAlt = false;
      // 去除网络图片超链接跳转地址
      editor.config.showLinkImgHref = false;
      editor.config.uploadImgMaxSize = 2 * 1024 * 1024 // 将图片大小限制为 2M
      editor.config.uploadImgShowBase64 = true // base 64 存储图片
      // 自己实现图片上传
      // editor.config.customUploadImg = (resultFiles, insertImgFn) => {
      //   this.updateImg(resultFiles[0], insertImgFn);
      // }
      // 创建富文本实例
      editor.create();
      //内容回显
      editor.txt.html(this.value)
      //判断是否禁用编辑栏
      if (this.disable) {
        editor.disable()
      }
    },
    methods: {
      onChange(newHtml) {
        this.$emit('valueChange',newHtml)
      },
    }
  }
</script>

<style lang="less">
  #TextEditor {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid #f0f2f5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);


    #TextEditorNav {
      height: 40px;
      width: 100%;
      .w-e-toolbar{
        background-color: #f0f2f5;
      }
    }

    #TextEditorMain {
      flex: 1;
      width: 100%;
      /*margin-top: 10px;*/
      /*margin-bottom: 10px;*/
      display: flex;
      justify-content: space-around;

      #TextEditorMainCode,
      #TextEditorMainShow {
        width: 100%;
        height: 40vh;
        min-height: 400px;
        .w-e-text-container{
          height: 40vh;
          min-height: 400px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
        }
      }
    }

    .toolbar {
      border: 1px solid #ccc;
    }

    .text {
      border: 1px solid #ccc;
      min-height: 400px;
    }
  }
</style>
